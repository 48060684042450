<!-- =========================================================================================
    File Name: FormValidationTypes.vue
    Description: Validation types
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="resource-card">
    <!-- div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
        <vx-card>
          <h5 class="mb-2">Resource information</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>

          <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
            <vs-input v-validate="'alpha_spaces'" name="name" v-model="name" />
            <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
          </div>
        </vx-card>
      </div>
    </div -->
    <!-- div class="vx-row">
      <div class="vx-col lg:w-3/3 w-full">
        <vx-card
          title="Appointment Type information"
          subtitle="Be sure to check &quot;Deliver to this address&quot; when you have finished"
          class="mb-base">
          <form data-vv-scope="add-new-address">
            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
              <h5>Patient Type</h5>
                <div class="demo-alignment mb-5">
                  <vs-radio v-model="patientType" vs-value="existing">Exsiting</vs-radio>
                  <vs-radio v-model="patientType" vs-value="new">New</vs-radio>
                </div>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
              <h5>Gender</h5>
                <div class="demo-alignment mb-5">
                  <vs-radio v-model="gender" vs-value="female">Female</vs-radio>
                  <vs-radio v-model="gender" vs-value="male">Male</vs-radio>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="firstName"
                  label="firstName"
                  v-model="firstName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.firstName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.firstName') }}</span>
              </div>

              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="lastName"
                  label="lastName"
                  v-model="lastName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.lastName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.lastName') }}</span>
              </div>

              <div class="vx-col sm:w-1/3 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="fullName"
                  label="fullName"
                  v-model="fullName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.fullName')"
                  class="text-danger"
                >{{ errors.first('add-new-address.fullName') }}</span>
              </div>
            </div>

            <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
                <vs-input
                  v-validate="'required|email'"
                  data-vv-as="field"
                  name="email"
                  label="email"
                  v-model="email"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.email')"
                  class="text-danger"
                >{{ errors.first('add-new-address.email') }}</span>
              </div>

              <div class="vx-col sm:w-1/2 w-full">
                <vs-input
                  v-validate="'required|digits:10'"
                  name="mobileNum"
                  label="Mobile Number:"
                  v-model="mobileNum"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.mobileNum')"
                  class="text-danger"
                >{{ errors.first('add-new-address.mobileNum') }}</span>
              </div>
            </div>



            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <vs-input
                  v-validate="'required'"
                  name="state"
                  label="State:"
                  v-model="state"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-address.state')"
                  class="text-danger"
                >{{ errors.first('add-new-address.state') }}</span>
              </div>


            </div>
            <div class="vx-row">
              <div class="vx-col sm:w-1/1 w-full">
                <div class="demo-alignment mb-5">
                  <vs-radio v-model="agreeTermsConditions" vs-value="existing">Agree to terms and conditions</vs-radio>
                </div>
              </div>
            </div>

            <vs-button  class="mt-6 ml-auto flex" @click="create_booking_details(pkg_id,day_no,slot_id)" >Complete your booking</vs-button>

          </form>

        </vx-card>
      </div>

    </div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
        <vx-card>
          <h5 class="mb-2">Appointment Type information</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>



          <vs-divider></vs-divider>
          <div class="vx-row">
              <div class="vx-col sm:w-1/2 w-full">
              <vs-input v-validate="'alpha_spaces'" name="appointmentName" v-model="appointmentName" />
              <span
                class="text-danger text-sm"
                v-show="errors.has('appointmentName')"
              >{{ errors.first('appointmentName') }}</span>
            </div>
          </div>

          <vs-divider></vs-divider>
          <h5 class="mb-2">Timezone</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>
        </vx-card>
      </div>
    </div -->

    <!-- div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 lg:w-1/1 mb-base">
        <vx-card>
          <h5 class="mb-2">Resource information</h5>
          <p class="text-black">asasasas</p>
        <form>    <p class="text-grey">asasasas</p>

          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full">
              <vs-input v-validate="'alpha_spaces'" name="name" v-model="name" />
              <span
                class="text-danger text-sm"
                v-show="errors.has('name')"
              >{{ errors.first('name') }}</span>
            </div>
          </div>

          <vs-divider></vs-divider>
          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                v-model="application_local"
                :clearable="false"
                :options="application"
                v-validate="'required'"
                name="application"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('application')"
              >{{ errors.first('application') }}</span>
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <vs-input v-validate="'num'" name="Quantity" v-model="quantity" />
              <span
                class="text-danger text-sm"
                v-show="errors.has('quantity')"
              >{{ errors.first('quantity') }}</span>
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <vs-input v-validate="'alpha_spaces'" name="name" v-model="name" />
              <span
                class="text-danger text-sm"
                v-show="errors.has('name')"
              >{{ errors.first('name') }}</span>
            </div>
          </div>
          </form>
        </vx-card>
      </div>
    </div -->

<!-- Resource information -->
 <!-- p><pre>data: {{$data }}</pre></p -->
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/1 lg:w-1/2 mb-base">
        <vx-card>
          <h5 class="mb-2">New Listing</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>
  <form data-vv-scope="add-new-listing">
          <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="listingName"
                  label="listingName"
                  v-model="listingName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-listing.listingName')"
                  class="text-danger"
                >{{ errors.first('add-new-listing.listingName') }}</span>
          </div>
            </div>
          <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full">
                <vs-input
                  v-validate="'required|alpha_spaces'"
                  data-vv-as="field"
                  name="locationName"
                  label="locationName"
                  v-model="listingName"
                  class="w-full mt-5"
                />
                <span
                  v-show="errors.has('add-new-listing.locationName')"
                  class="text-danger"
                >{{ errors.first('add-new-listing.locationName') }}</span>
          </div>
            </div>

          <vs-divider></vs-divider>
          <div class="vx-row">
            <div class="vx-col sm:w-1/4 w-full">
              <v-select
                v-model="durationHelper_local"
                :clearable="false"
                :options="durationHelper"
                v-validate="'required'"
                name="durationPeriod"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.durationPeriod')"
              >{{ errors.first('add-new-listing.durationPeriod') }}</span>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <v-select
                v-model="futureBookingLimitDurationHelper_local"
                :clearable="false"
                :options="futureBookingLimitDurationHelper"
                v-validate="'required'"
                name="futureBookingLimit"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.futureBookingLimit')"
              >{{ errors.first('add-new-listing.futureBookingLimit') }}</span>
            </div>
            <div class="vx-col sm:w-1/4 w-full">
              <v-select
                v-model="minCancellationNoticeHelper_local"
                :clearable="false"
                :options="minCancellationNoticeHelper"
                v-validate="'required'"
                name="minCancellationNotice"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.minCancellationNotice')"
              >{{ errors.first('add-new-listing.minCancellationNotice') }}</span>
            </div>
             <div class="vx-col sm:w-1/4 w-full">
              <v-select
                v-model="minBookingNoticeHelper_local"
                :clearable="false"
                :options="minBookingNoticeHelper"
                v-validate="'required'"
                name="minBookingNotice"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.minBookingNotice')"
              >{{ errors.first('add-new-listing.minBookingNotice') }}</span>
            </div>


          </div>
          <vs-divider></vs-divider>
          <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full">
              <v-select
                v-model="buffer_local"
                :clearable="false"
                :options="buffer"
                v-validate="'required'"
                name="buffer"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.buffer')"
              >{{ errors.first('add-new-listing.buffer') }}</span>
            </div>


          </div>
          <vs-divider></vs-divider>
          <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full">
              <v-select
                v-model="bookableResource_local"
                :clearable="false"
                :options="bookableResource"
                v-validate="'required'"
                name="bookableResource"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
             <span
                class="text-danger text-sm"
                v-show="errors.has('add-new-listing.bookableResource')"
              >{{ errors.first('add-new-listing.bookableResource') }}</span>
            </div>


          </div>
          <vs-divider></vs-divider>
          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                v-model="daysOpeningHelper_local"
                :clearable="false"
                :options="daysHelper"
                v-validate="'required'"
                name="dayInWeek"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('dayInWeek')"
              >{{ errors.first('dayInWeek') }}</span>
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <flat-pickr :config="configdateTimePicker" v-model="startOpeningTime" />
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <flat-pickr :config="configdateTimePicker" v-model="endOpeningTime" />
            </div>
          </div>


          </form>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/3 lg:w-1/2 mb-base">
        <vx-card>
          <h5 class="mb-2">Recurring working hours</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>


          <div class="vx-row">
            <div class="vx-col sm:w-1/3 w-full">
              <v-select
                v-model="daysHelper_local"
                :clearable="false"
                :options="daysHelper"
                v-validate="'required'"
                name="dayInWeek"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span
                class="text-danger text-sm"
                v-show="errors.has('dayInWeek')"
              >{{ errors.first('dayInWeek') }}</span>
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <flat-pickr :config="configdateTimePicker" v-model="startTime" />
            </div>

            <div class="vx-col sm:w-1/3 w-full">
              <flat-pickr :config="configdateTimePicker" v-model="endTime" />
            </div>
          </div>

          <vs-divider></vs-divider>
          <h5 class="mb-2">Timezone</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>
        </vx-card>
      </div>
      <div class="vx-col w-full sm:w-1/2 lg:w-1/2 mb-base">
        <vx-card>
          <h5 class="mb-2">Resource {{ type }} costing</h5>
          <p class="text-black">asasasas</p>
          <p class="text-grey">asasasas</p>
          <div class="flex justify-between flex-wrap">
            <form>
              <div class="vx-row">
                <div class="vx-col">
                  <vs-input
                    v-validate="'alpha_spaces'"
                    label="costRate"
                    name="costRate"
                    v-model="description"
                    class="mt-5 w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('costRate')"
                  >{{ errors.first('costRate') }}</span>
                </div>

                <div class="vx-col">
                  <vs-input
                    label="totalTime"
                    name="totalTime"
                    v-model="totalTime"
                    class="mt-5 w-full"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('totalTime')"
                  >{{ errors.first('totalTime') }}</span>
                </div>
                <div v-show="viewEquipement">
                  <div class="vx-col">
                    <vs-input
                      v-validate="'alpha'"
                      label="depreciationCost"
                      name="depreciationCost"
                      v-model="depreciationCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('depreciationCost')"
                    >{{ errors.first('depreciationCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="maintainmentCost"
                      name="maintainmentCost"
                      v-model="maintainmentCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('maintainmentCost')"
                    >{{ errors.first('maintainmentCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="totalEquipmentCost"
                      name="totalEquipmentCost"
                      v-model="maintainmentCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('totalEquipmentCost')"
                    >{{ errors.first('totalEquipmentCost') }}</span>
                  </div>
                </div>

                <div v-show="viewPersonel">
                  <div class="vx-col">
                    <vs-input
                      label="benefits"
                      name="benefits"
                      v-model="totalTime"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('benefits')"
                    >{{ errors.first('benefits') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="itSupport"
                      name="itSupport"
                      v-model="itSupport"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('itSupport')"
                    >{{ errors.first('itSupport') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="malpracticeInsurance"
                      name="malpracticeInsurance"
                      v-model="malpracticeInsurance"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('malpracticeInsurance')"
                    >{{ errors.first('malpracticeInsurance') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="miscellaneous"
                      name="miscellaneous"
                      v-model="miscellaneous"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('miscellaneous')"
                    >{{ errors.first('miscellaneous') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="officeExpenses"
                      name="officeExpenses"
                      v-model="officeExpenses"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('officeExpenses')"
                    >{{ errors.first('officeExpenses') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input label="salary" name="salary" v-model="salary" class="mt-5 w-full" />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('salary')"
                    >{{ errors.first('salary') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="supervision"
                      name="supervision"
                      v-model="supervision"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('supervision')"
                    >{{ errors.first('supervision') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="totalPersonnelCost"
                      name="totalPersonnelCost"
                      v-model="totalPersonnelCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('totalPersonnelCost')"
                    >{{ errors.first('totalPersonnelCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="trainingNTravel"
                      name="trainingNTravel"
                      v-model="trainingNTravel"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('trainingNTravel')"
                    >{{ errors.first('trainingNTravel') }}</span>
                  </div>
                </div>

                <div v-show="viewSpace">
                  <!-- Sapce -->
                  <div class="vx-col">
                    <vs-input
                      label="depreciationCost"
                      name="depreciationCost"
                      v-model="depreciationCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('depreciationCost')"
                    >{{ errors.first('depreciationCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="housekeepingCost"
                      name="housekeepingCost"
                      v-model="housekeepingCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('housekeepingCost')"
                    >{{ errors.first('housekeepingCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="maintainmentCost"
                      name="maintainmentCost"
                      v-model="maintainmentCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('maintainmentCost')"
                    >{{ errors.first('maintainmentCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="operatingCost"
                      name="operatingCost"
                      v-model="maintainmentCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('operatingCost')"
                    >{{ errors.first('operatingCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="realEstateCost"
                      name="realEstateCost"
                      v-model="realEstateCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('realEstateCost')"
                    >{{ errors.first('realEstateCost') }}</span>
                  </div>

                  <div class="vx-col">
                    <vs-input
                      label="totalSpaceCost"
                      name="totalSpaceCost"
                      v-model="totalSpaceCost"
                      class="mt-5 w-full"
                    />
                    <span
                      class="text-danger text-sm"
                      v-show="errors.has('totalSpaceCost')"
                    >{{ errors.first('totalSpaceCost') }}</span>
                  </div>
                </div>
              </div>
              <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">Submit</vs-button>
            </form>
          </div>
          <vs-divider></vs-divider>
        </vx-card>
      </div>
    </div>

    <h5 class="mb-2">Cost - {{ title }}</h5>
    <p class="text-black">{{ department}}</p>

    <vs-table v-model="selected" @selected="handleSelected" :data="users">
      <template slot="thead">
        <vs-th>
          <h5>Cost Type</h5>
        </vs-th>
        <vs-th>
          <h5>Amount(EUR)</h5>
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>

          <vs-td>{{ data[indextr].value }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total(EUR)</h5>
          </vs-td>
          <vs-td>
            <h5>{{ totalPersonnelCost}}</h5>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <h5 class="mb-2">Capacity - {{ title }}</h5>
    <p class="text-black">{{ department}}</p>
    <p class="text-black">Annual Salary: {{ salary }}</p>

    <vs-table v-model="selected" @selected="handleSelected" :data="capacity.variables">
      <template slot="thead">
        <vs-th>
          <h5>Capacity Calculation</h5>
        </vs-th>
        <vs-th>
          <h5>Allocated Time (per day)</h5>
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>

          <vs-td>{{ data[indextr].value }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total time (min/year)</h5>
          </vs-td>
          <vs-td>
            <h5>{{ totalTime}}</h5>
          </vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Cost rate(EUR/min)</h5>
          </vs-td>
          <vs-td>
            <h5>{{ costRate}}</h5>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <p class="text-black">{{ capacity.notes[0]}}</p>
    <p class="text-black"> {{ capacity.notes[1] }}</p>


<h5 class="mb-2">Consumables - {{ title }}</h5>
    <p class="text-black">{{ department}}</p>
        <p class="text-black">{{ speciality}}</p>

    <vs-table v-model="selected" @selected="handleSelected" :data="consumables">
      <template slot="thead">
        <vs-th>
          <h5>Consumable name</h5>
        </vs-th>
        <vs-th>
          <h5>Consumables cost (EUR)</h5>
        </vs-th>
        <vs-th>
          <h5>Quantity</h5>
        </vs-th>
        <vs-th>
          <h5>Total cost (EUR)</h5>
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>

          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total(EUR)</h5>
          </vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td>
            <h5>{{ totalPersonnelCost}}</h5>
          </vs-td>
        </vs-tr>

      </template>
    </vs-table>



    <br />

    <pre>{{ selected }}</pre>



<h5 class="mb-2">Space  - {{ title }}</h5>
    <p class="text-black">Space name Area - {{ department}}</p>
        <p class="text-black">{{ speciality}}</p>

    <vs-table v-model="selected" @selected="handleSelected" :data="consumables">
      <template slot="thead">
        <vs-th>
          <h5> Area (sqm)</h5>
        </vs-th>
        <vs-th>
          <h5>New construcion costs per sqm (EUR/sqm)</h5>
        </vs-th>
        <vs-th>
          <h5>Useful life (years)</h5>
        </vs-th>
        <vs-th>
          <h5>Annual construcion depreciation per sqm ($/sqm)</h5>
        </vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>

          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total(EUR)</h5>
          </vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td>
            <h5>{{ totalPersonnelCost}}</h5>
          </vs-td>
        </vs-tr>

      </template>
    </vs-table>
    <br />
   <pre>{{ selected }}</pre>

    <br />




<h5 class="mb-2">Space costs associated with the radiology suite - {{ title }}</h5>
    <p class="text-black">Space name Area - {{ department}}</p>
        <p class="text-black">{{ speciality}}</p>

        <vs-table v-model="selected" @selected="handleSelected" :data="consumables">
      <template slot="thead">
        <vs-th><h5>Annual maintenance costs per sqm, ($/sqm)</h5></vs-th>
        <vs-th> <h5>Annual operating costs (incl. utilities) per sqm ($/sqm))</h5></vs-th>
        <vs-th><h5>Yearly housekeeping costs per sq ($/sqm)</h5></vs-th>
        <vs-th><h5>Real estate value per sqm ($/sqm)</h5></vs-th>

        <vs-th><h5>Real estate life (yrs)</h5></vs-th>
        <vs-th> <h5>Yearly real estate cost per sqm ($/sqm)</h5></vs-th>
        <vs-th><h5>Total annual space cost per square ($/sqm)</h5></vs-th>
        <vs-th><h5>Total annual space cost($)</h5></vs-th>

        <vs-th> <h5>Availability timeframe </h5></vs-th>
        <vs-th><h5>Availability (minutes)</h5></vs-th>
        <vs-th><h5>Total annual space</h5></vs-th>
        <vs-th><h5>Cost per minute ($/min)</h5></vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total(EUR)</h5>
          </vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td>
            <h5>{{ totalPersonnelCost}}</h5>
          </vs-td>
        </vs-tr>

      </template>
    </vs-table>

    <pre>{{ selected }}</pre>
   <br />







   <h5 class="mb-2">Equipment - {{ title }}</h5>
    <p class="text-black">Equipment name  - {{ department}}</p>
        <p class="text-black">{{ speciality}}</p>

        <vs-table v-model="selected" @selected="handleSelected" :data="consumables">
      <template slot="thead">
        <vs-th><h5>Replacement cost</h5></vs-th>
        <vs-th> <h5>Useful life (years)</h5></vs-th>
        <vs-th><h5>Yearly depreciation</h5></vs-th>
        <vs-th><h5>Yearly maintenance costs (%)</h5></vs-th>
        <vs-th><h5>Yearly maintainance costs ($)</h5></vs-th>

        <vs-th> <h5>Equipment cost per year ($/yr)</h5></vs-th>
        <vs-th><h5>Availability timeframe</h5></vs-th>
        <vs-th><h5>Availability (minutes)</h5></vs-th>
        <vs-th><h5>Capacity cost rate ($/min)</h5></vs-th>


      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
        </vs-tr>


      </template>
    </vs-table>

    <pre>{{ selected }}</pre>
   <br />


<h5 class="mb-2">Actual Cost of Each Care Segment and the Full Cycle of Care - {{ title }}</h5>
    <p class="text-black">X-ray taken in the pre-operative testing segment - {{ department}}</p>
        <p class="text-black">{{ speciality}}</p>

        <vs-table v-model="selected" @selected="handleSelected" :data="consumables">
      <template slot="thead">
        <vs-th><h5>Segment</h5></vs-th>
        <vs-th> <h5>Activity</h5></vs-th>
        <vs-th><h5>Personel type</h5></vs-th>
        <vs-th><h5>Time (min)</h5></vs-th>

        <vs-th><h5>Probability 1: % of time step takes place</h5></vs-th>
        <vs-th> <h5>Probability 2: % of time personnel is used</h5></vs-th>
        <vs-th><h5>Space </h5></vs-th>
        <vs-th><h5>Type of equipment</h5></vs-th>
        <vs-th> <h5>Total </h5></vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>
          <vs-td>{{ data[indextr].quantity }}</vs-td>
          <vs-td>{{ data[indextr].value }}</vs-td>

          <vs-td>{{ data[indextr].label }}</vs-td>
        </vs-tr>
        <vs-tr>
          <vs-td>
            <h5>Total(EUR)</h5>
          </vs-td>
          <vs-td></vs-td>
          <vs-td></vs-td>
          <vs-td>
            <h5>{{ totalPersonnelCost}}</h5>
          </vs-td>
        </vs-tr>

      </template>
    </vs-table>

    <pre>{{ selected }}</pre>
   <br />

  </div>
</template>

<script>
import axios from "@/axios.js"
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    vSelect,
    flatPickr
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //data_local: JSON.parse(JSON.stringify(this.data)),
      settingList: null,
      listingName:"",
      locationName:"",
      speciality: "Operating Room",
      viewSpace: true,
      viewPersonel: true,
      viewEquipement: true,
      startTime: null,
      endTime: null,
      daysOpeningHelper_local:"Monday",
      startOpeningTime: null,
      endOpeningTime: null,
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
        time_24hr: true,
        minTime: "09:00",
        maxTime: "17:30",
        dateFormat: "H:i",
        defaultDate: "13:45",
        inline: false
      },
      capacity: {
        variables:[
        { label: "hourPerDay", value: 8.5, capacityId: 1 },
        { label: "hourBreak", value: 1, capacityId: 1 },
        { label: "nonCustomerMeetingTime", value: 0.5, capacityId: 1 },
        { label: "trainingConferenceDaysPerYear", value: 5, capacityId: 1 },
        { label: "weekEndPublicHolidaysDaysPerYear", value: 110, capacityId: 1 },
        { label: "vacationDaysPerYear", value: 26, capacityId: 1 }],
        notes: ["Net 7hr per day x 60 min/hr = 420 min/day", "420 min/day x net 224 working day/yr = 94080 min/yr"]
      },
       consumables:[
        { label: "Hip implant", value: 4500, quantity: 1 },
        { label: "Custom hip pack", value: 350, quantity: 1 },
        { label: "Saw blade", value: 55, quantity: 2 },
        { label: "Suture material", value: 18, quantity: 4 }],
      type: "",
      name: "",
      costRate: 0,
      maintainmentCost: 0,
      //name: string,
      quantity: 0,
      timeZoneHour: 0,
      timeZoneMinute: 0,
      totalEquipmentCost: 0,
      totalTime: 0,
      //type: string,
      workingTimePerDayEndHour: [],
      workingTimePerDayEndMinute: [],
      workingTimePerDayStartHour: [],
      workingTimePerDayStartMinute: [],
      //personnel
      benefits: 0,
      //costRate: 0,
      itSupport: 0,
      malpracticeInsurance: 0,
      miscellaneous: 0,
      //name: string,
      officeExpenses: 0,
      //quantity: 0,
      salary: 0,
      supervision: 0,
      totalPersonnelCost: 0,
      //totalTime: 0,
      trainingNTravel: 0,
      //space
      //costRate: 0,
      depreciationCost: 0,
      housekeepingCost: 0,
      //name: string,
      operatingCost: 0,
      //quantity: 0,
      realEstateCost: 0,
      totalSpaceCost: 0,
      //totalTime: 0,
      measurementUnitDispensing: "",
      sellingPrice: "",
      costPrice: "",
      defaultQty: "",
      defaultLabels: "",
      application_local: "take",
      quantity_local: "1",
      form_local: "tab/s",
      futureBookingLimitDurationHelper_local: "days",
      minCancellationNoticeHelper_local: "days",
      minBookingNoticeHelper_local: "days",
      frequencyHelper_local: "morning",
      daysHelper_local: "Monday",
      durationHelper_local: "hours",
      bookableResource_local: "none",
      defaultDosage: "",
      application: [
        { label: "Take", value: "take" },
        { label: "Apply", value: "apply" },
        { label: "Drop", value: "drop" }
      ],
      resources: [],
      selected: [],
      /*
costRate
totalTime
depreciationCost
maintainmentCost
totalEquipmentCost


malpracticeInsurance
miscellaneous



totalPersonnelCost

depreciationCost
housekeepingCost
maintainmentCost
operatingCost
realEstateCost
totalSpaceCost
*/
      title: "X-Ray Technician",

      department: "Imaging",
      users: [
        { label: "salary", value: "32000", costTypeId: 1 },
        { label: "supervision", value: "2", costTypeId: 2 },
        { label: "benefits", value: "8320", costTypeId: 3 },
        { label: "itSupport", value: "1000", costTypeId: 4 },
        { label: "assistan admin support", value: "0", costTypeId: 5 },
        { label: "trainingNTravel", value: "1000", costTypeId: 6 },
        { label: "officeExpenses", value: "0", costTypeId: 7 },
        { label: "malpracticeInsurance", value: "0", costTypeId: 8 },
        { label: "miscellaneous", value: "0", costTypeId: 9 }
      ],
      quantityOld: [
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" },
        { label: "8", value: "8" },
        { label: "9", value: "9" },
        { label: "10", value: "10" }
      ],
      bookableResource: [
        { label: "Doctor1", value: "D1" },
        { label: "Doctor2", value: "D2" },
        { label: "Doctor3", value: "D3" }
      ],
      form: [
        { label: "tab/s", value: "tab" },
        { label: "capsule/s", value: "capsule" },
        { label: "pill/s", value: "pill" },
        { label: "droplet/s", value: "droplet" }
      ],
      frequencyHelper: [
        { label: "Morning", value: "morning" },
        { label: "Afternoon", value: "afternoon" },
        { label: "Evening", value: "evening" }
      ],
      daysHelper: [
        { label: "Monday", value: "1" },
        { label: "Tuesday", value: "2" },
        { label: "Wednesday", value: "3" }
      ],
      durationHelper: [
        { label: "minutes", value: "1" },
        { label: "hours", value: "2" },
        { label: "days", value: "3" }
      ],
      futureBookingLimitDurationHelper: [
        { label: "days", value: "1", min: "1", max: "30" },
        { label: "weeks", value: "2", min: "1", max: "4"  },
        { label: "months", value: "3", min: "1", max: "3" }
      ],
      minCancellationNoticeHelper: [
        { label: "minutes", value: "1", min: "1", max: "30" },
        { label: "hours", value: "2", min: "1", max: "4"  },
        { label: "days", value: "3", min: "1", max: "3" }
      ],
      minBookingNoticeHelper: [
        { label: "minutes", value: "1", min: "30" },
        { label: "hours", value: "2", min: "1" },
        { label: "days", value: "3", min: "3" }
      ],
      buffer: [
        { label: "5 minutes", value: "5"},
        { label: "10 minutes", value: "10" },
        { label: "15 minutes", value: "15" },
        { label: "30 minutes", value: "30" },
        { label: "45 minutes", value: "45" },
        { label: "60 minutes", value: "60" },
        { label: "75 minutes", value: "75" },
        { label: "90 minutes", value: "90" }
      ]
    };
  },
  computed: {


    validateForm() {
      return !this.errors.any();
    }
  },
  methods: {
    /*
save Equipment
    {
  "costRate": 0,
  "depreciationCost": 0,
  "maintainmentCost": 0,
  "name": "string",
  "quantity": 0,
  "timeZoneHour": 0,
  "timeZoneMinute": 0,
  "totalEquipmentCost": 0,
  "totalTime": 0,
  "type": "string"
}
    */
    fetchSettings(id) {
      console.log("PackSetting  ID : " + id);

        var config = {
          headers: {'Access-Control-Allow-Origin': '*'}
        };
         axios.get("http://localhost:8090/company/getSettings?cnr=G7H8IJ",config)
            .then((response) => {
              console.log("START getSettings");
              this.settingList = response.data;

              console.log(response.data);
               console.log("END getSettings size" + this.settingList);

            });
    },
    createSettings() {

      var dataBook = {
        "automated": true,
        "reminder": true,
  "bookableResources": [
    {
      "system": "string",
      "type": {
        "text": "string"
      },
      "use": "string",
      "value": "string"
    }
  ],
  "bufferTime": 0,
  "duration": {
    "id": "string",
    "label": "string",
    "max": "string",
    "min": "string",
    "unit": "string",
    "value": "string"
  },
  "futureBookingLimit": {
    "id": "string",
    "label": "string",
    "max": "string",
    "min": "string",
    "unit": "string",
    "value": "string"
  },
  "minBookingNotice": {
    "id": "string",
    "label": "string",
    "max": "string",
    "min": "string",
    "unit": "string",
    "value": "string"
  },
  "minCancellationNotice": {
    "id": "string",
    "label": "string",
    "max": "string",
    "min": "string",
    "unit": "string",
    "value": "string"
  },
  "openingHours": [
    {
      "endTime": "string",
      "id": "string",
      "label": "string",
      "startTime": "string"
    }
  ],
    };

      var config = {
        headers: { "Content-Type": "application/json" }
      };
     console.log(this.$data,);
      //axios.post(HMinderEndPoint + postURL,"4a93ac04-1115-4634-8664-db27272f9323",config)
      axios.post("http://localhost:8090/company/addSettings?cnr=G7H8IJ",dataBook, config)
        .then(response => {
          console.log("addSettings");
          console.log(response);
          this.appointmentId = response.data;
          console.log("addSettings");


        return this.$router.push('/apps/preventiveHealth/booking-success').catch(() => {})

        });
    },
    handleSelected(tr) {
      this.$vs.notify({
        title: `Selected ${tr.label}`,
        text: `Email: ${tr.value}`
      });
    },
    capitalize(str) {
      return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
    },
    save_changes() {
      /* eslint-disable */
      if (!this.validateForm) return;

      // Here will go your API call for updating data
      // You can get data in "this.data_local"

      /* eslint-enable */
    },
    reset_data() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
    update_avatar() {
      // You can update avatar Here
      // For reference you can check dataList example
      // We haven't integrated it here, because data isn't saved in DB
    },
    submitForm() {
      this.$validator.validateAll().then(result => {
        this.defaultDosage =
          this.application_local.value +
          this.quantity_local.value +
          this.form_local.value +
          " for " +
          this.frequencyHelper_local.value +
          " for  " +
          this.daysHelper_local.value;
        if (result) {
          // if form have no errors
          alert("form submitted!");
        } else {
          // form have errors
        }
      });
    }
  },
    created() {
    //this.create_booking_details(this.$route.params.pkg_id);
    this.settingList = this.fetchSettings(1);
    //this.createSettings();

  }
};
</script>
